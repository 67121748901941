
import { Component, Vue } from "vue-property-decorator";
import { UserService, GaodeService, WechatService, supplierService } from "src/services";
import { Toast } from "vant";
import { tools } from "src/utils";
import { ILogin } from "src/model";
import { DesSecretUtils } from "src/utils";
import privacy from "./privacy.vue";
import agreement from "./agreement.vue";
import zfagreement from "./zf-agreement.vue";
import zfprivacy from "./zf-privacy.vue";
import ermInfo from "./ermInfo.vue";
import rightsAgreement from "./rights-agreement.vue";
import rightsPrivacy from "./rights-privacy.vue";
import globalConfig from "../config/index";
import Register from "./register.vue";
const showRightsCode = globalConfig.showRightsCode;
// import sensorsUtils from "src/utils/sensorsUtils"

Component.registerHooks([
  "beforeRouteEnter",
  "beforeRouteLeave",
  "beforeRouteUpdate", // for vue-router 2.2+
]);

@Component({
  components: {
    agreement,
    privacy,
    rightsAgreement,
    rightsPrivacy,
    ermInfo,
    Register,
    zfagreement,
    zfprivacy,
  },
})
export default class Login extends Vue {
  /**
   * 显示协议弹框
   * @private
   * @returns boolean
   */
  private showOverlay: boolean = false;

  /**
   * 服务隐私协议id
   * @private
   * @returns number
   */
  private ermInfoId: number = null;

  /**
   * 是否显示服务隐私协议
   * @private
   * @returns number
   */
  private showErmInfoModal: boolean = false;

  /**
   * 是否显示服务协议
   * @private
   * @returns boolean
   */
  private showAgreement: boolean = false;

  /**
   * 是否显示隐私协议
   * @private
   * @returns boolean
   */
  private showPrivacy: boolean = false;

  /**
   * 是否显示中福服务协议
   * @private
   * @returns boolean
   */
  private showZfAgreement: boolean = false;

  /**
   * 是否显示中福隐私协议
   * @private
   * @returns boolean
   */
  private showZfPrivacy: boolean = false;

  /**
   * 是否显示权益隐私协议
   * @private
   * @returns boolean
   */
  private showRightsAgreement: boolean = false;

  /**
   * 是否显示权益隐私协议
   * @private
   * @returns boolean
   */
  private showRightsPrivacy: boolean = false;

  /**
   * 是否默认记录密码
   * @private
   * @returns boolean
   */
  private isRememberPassword: boolean = false;

  /**
   * 是否同意协议
   * @private
   * @returns boolean
   */
  private checkedAgreement: boolean = false;

  /**
   * 登录数据模型
   * @private
   * @returns ILogin
   */
  private modelData: ILogin = {
    code: "", // 企业编号
    account: "", // 登入账号
    password: "", // 登入密码
    loginType: null, // 登录类型
  };

  /**
   * 是否显示密码
   * @private
   * @returns string
   */
  private showPwd: boolean = false;

  /**
   * 获取当前登入机构信息
   * @private
   * @returns void
   */
  private get tip(): any {
    let appid = localStorage.getItem("appid");
    let employerConfig = globalConfig.employerConfig[appid];
    let tempData = this.loginTypes.filter((res) => res.loginType == this.modelData.loginType);
    return {
      accountTip:
        (employerConfig && employerConfig.accountTip) || "请输入" + ((tempData.length > 0 && tempData[0].loginTypeStr) || "手机号"),
      pwdTip: (employerConfig && employerConfig.pwdTip) || "请输入密码",
    };
  }

  /**
   * 获取当前登入机构信息
   * @private
   * @returns void
   */
  private get hasInputForm(): any {
    if (this.modelData.account && this.modelData.password) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * 是否显示注册
   * @private
   * @returns void
   */
  private get showRegister(): any {
    return this.orgInfo.isOpenRegister || false;
  }

  /**
   * 获取当前登入机构信息
   * @private
   * @returns void
   */
  private get orgInfo(): any {
    return this.$store.getters.orgInfo || {};
  }

  /**
   * 获取当前登入信息
   * @private
   * @returns void
   */
  private get showRights(): any {
    return showRightsCode.includes(localStorage.getItem("appid"));
  }

  /**
   * 获取routeName
   * @private
   * @returns void
   */
  private get routeName(): any {
    return this.$route.query.routeName || "";
  }

  /**
   * 组件创建钩子
   * @private
   * @returns void
   */
  private created(): void {
    localStorage.removeItem("isFormAi");
    localStorage.removeItem("openid");
    this.modelData.code = localStorage.getItem("appid");
    // 获取机构信息
    this.getOrgInfo(this.modelData.code);
  }

  /**
   * 记住密码重新赋值
   * @private
   * @returns void
   */
  private onSetModelData(): void {
    let loginDataStr = localStorage.getItem("loginData");
    if (loginDataStr) {
      if (
        this.orgInfo.isUnited ||
        this.loginTypes.some((res) => res.loginType == JSON.parse(DesSecretUtils.encrypt(loginDataStr)).loginType)
      ) {
        this.isRememberPassword = true;
        this.modelData = JSON.parse(DesSecretUtils.encrypt(loginDataStr));
      }
    }
  }

  /**
   * 登录类型
   * @private
   * @returns any
   */
  private loginTypes: Array<any> = [];

  /**
   * 获取机构详情
   * @private
   * @returns void
   */
  private async getOrgInfo(code: string): Promise<void> {
    try {
      let { content: result } = await UserService.instance.getOrgInfo(code);
      if (result.data) {
        if (!result.data.isUnited && result.data.loginTypes) {
          this.loginTypes = result.data.loginTypes;
          if (this.loginTypes.length > 0) {
            this.modelData.loginType = this.loginTypes[0].loginType;
          }
        }
        this.$store.dispatch("setOrgInfo", result.data);
        if (result.data.isSelf) {
          // 个人
          this.$router.replace({ name: "register" });
        } else {
          this.getGeolocation();
        }
      }
      this.onSetModelData();
    } catch (err) {
      this.onSetModelData();
      Toast(err);
    }
  }

  /* 获取当前位置信息
   * @private
   * @returns void
   */
  private async getGeolocation(): Promise<void> {
    let appid = localStorage.getItem("appid");
    let employerConfig = globalConfig.employerConfig[appid];
    if (employerConfig && employerConfig.unLocation) {
      return;
    }
    this.$store.dispatch("setLocation", null);
    if (await tools.isWechat()) {
      // 如果是微信环境
      this.weixinLocation();
    } else {
      let data = await GaodeService.instance.getBaiduLocation();
      this.$store.dispatch("setLocation", data);
    }
  }

  /* 业务员隐私协议显示
   * @private
   * @returns void
   */
  private async onTermInfos(id): Promise<void> {
    this.ermInfoId = id;
    this.showErmInfoModal = true;
  }

  /* 业务员隐私协议关闭
   * @private
   * @returns void
   */
  private async onCloseErmInfoModal(): Promise<void> {
    this.showErmInfoModal = false;
  }

  /**
   * 登录
   * @private
   * @returns void
   */
  private async onLogin(): Promise<void> {
    if (this.orgInfo.isUnited) {
      this.uniteLogin();
    } else {
      this.onEmployerLogin();
    }
  }

  /**
   * 登录
   * @private
   * @returns void
   */
  private async onEmployerLogin(): Promise<void> {
    try {
      if (!this.checkedAgreement) {
        this.showOverlay = true;
        return;
      }
      let { content: result } = await UserService.instance.login(this.modelData);
      // 绑定神策用户数据
      // sensorsUtils.bind("identity_Id",result.data.userId +"");

      if (this.isRememberPassword) {
        let loginData = DesSecretUtils.decrypt(JSON.stringify(this.modelData));
        localStorage.setItem("loginData", loginData);
      } else {
        localStorage.removeItem("loginData");
      }

      if (result.data) {
        this.$store.dispatch("setUserInfo", result.data);
        this.$nextTick(() => {
          this.$router.replace({ name: "home" });
        });
      }

      // 重置预约须知
      this.resetRead();
    } catch (err) {
      Toast(err);
    }
  }

  /**
   * 业务员登录
   * @private
   * @returns void
   */
  private async uniteLogin(): Promise<void> {
    try {
      if (!this.checkedAgreement) {
        this.showOverlay = true;
        return;
      }
      let { content: result } = await UserService.instance.uniteLogin(this.modelData);
      // 绑定神策用户数据
      // sensorsUtils.bind("identity_Id",result.data.userId +"");

      if (this.isRememberPassword) {
        let loginData = DesSecretUtils.decrypt(JSON.stringify(this.modelData));
        localStorage.setItem("loginData", loginData);
      } else {
        localStorage.removeItem("loginData");
      }

      if (result.data) {
        // 只有一个企业
        if (result.data.isOnlyOne) {
          this.$store.dispatch("setOrgInfo", result.data.orgInfo);
          this.$store.dispatch("setUserInfo", result.data);
          this.$nextTick(() => {
            if (this.routeName == "report") {
              this.$router.replace({ name: "reportAppointmentList" });
            } else {
              this.$router.replace({ name: "home" });
            }
          });
        } else {
          this.$store.dispatch("setUserInfo", result.data);
          this.$nextTick(() => {
            this.$router.replace({ name: "employerList", query: { routeName: this.routeName } });
          });
        }
      }

      // 重置预约须知
      this.resetRead();
    } catch (err) {
      Toast(err);
    }
  }

  /**
   * 重置预约须知
   * @private
   * @returns void
   */
  protected async resetRead() {
    try {
      await supplierService.instance.resetRead();
    } catch (error) {
      Toast(error);
    }
  }

  /**
   * 组件进来之前
   * @private
   * @returns void
   */
  private beforeRouteEnter(to, from, next): void {
    let orgInfo = localStorage.getItem("orgInfo");
    let appid = localStorage.getItem("appid");
    if (orgInfo && JSON.parse(orgInfo).isSelf) {
      window.location.href = "/" + appid + "/register";
    } else {
      if (!tools.isAndroid() && `/${appid}${to.path}` !== location.pathname) {
        location.assign(`/${appid}${to.fullPath}`);
      } else {
        next();
      }
    }
  }

  /* 获取当前位置信息 -- 微信
   * @private
   * @returns void
   */
  private async weixinLocation(): Promise<void> {
    let currentUrl = location.href;
    let { content: result } = await WechatService.instance.getWechatJSSDK(currentUrl);
    let loca = await WechatService.instance.getWechatLocation(result); // 获取经纬度-腾讯
    let locat = await GaodeService.instance.getCity((loca as any).longitude, (loca as any).latitude); // 通过经纬度获取城市-高德
    this.$store.dispatch("setLocation", locat);
  }

  /* 同意协议
   * @private
   * @returns void
   */
  private async onAgree() {
    this.checkedAgreement = true;
    this.showOverlay = false;
    // 点击同意之后直接登录
    try {
      this.onLogin();
    } catch (err) {
      Toast(err);
    }
  }

  /* 忘记密码密码
   * @private
   * @returns void
   */
  private onRetrievePwd(): void {
    this.$router.push({ name: "retrievePwd" });
  }

  /* 跳转隐私协议
   * @private
   * @returns void
   */
  private onPrivacy(): void {
    this.showPrivacy = true;
  }

  /* 关闭隐私协议
   * @private
   * @returns void
   */
  private closePrivacy(): void {
    this.showPrivacy = false;
  }

  /* 跳转用户协议
   * @private
   * @returns void
   */
  private onAgreement(): void {
    this.showAgreement = true;
  }

  /* 关闭用户协议
   * @private
   * @returns void
   */
  private closeAgreement(): void {
    this.showAgreement = false;
  }

  /* 关闭中福服务协议
   * @private
   * @returns void
   */
  private closeZfAgreement(): void {
    this.showZfAgreement = false;
  }

  /* 关闭中福隐私政策
   * @private
   * @returns void
   */
  private closeZfpPrivacy(): void {
    this.showZfPrivacy = false;
  }

  /* 跳转中福服务协议
   * @private
   * @returns void
   */
  private onzfAgreement(): void {
    this.showZfAgreement = true;
  }

  /* 跳转中福隐私协议
   * @private
   * @returns void
   */
  private onzfPrivacy(): void {
    this.showZfPrivacy = true;
  }

  /* 跳转携保权益协议
   * @private
   * @returns void
   */
  private onRightsAgreement(): void {
    this.showRightsAgreement = true;
  }

  /* 关闭携保权益协议
   * @private
   * @returns void
   */
  private closeRightsAgreement(): void {
    this.showRightsAgreement = false;
  }

  /* 跳转携保隐私
   * @private
   * @returns void
   */
  private onRightsPrivacy(): void {
    this.showRightsPrivacy = true;
  }

  /* 跳转携保隐私
   * @private
   * @returns void
   */
  private closeRightsPrivacy(): void {
    this.showRightsPrivacy = false;
  }

  /* 注册
   * @private
   * @returns void
   */
  private onToRegister(): void {
    this.$router.push({ name: "registerMain" });
  }

  /* 改变登录方式
   * @private
   * @returns void
   */
  private onChangeTab(type): void {
    this.modelData.loginType = type;
  }
}
