
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import EnumUtils from "src/utils/enum-utils";
import { RELATION, MARRIAGE_STATUS_REGISTER, ID_TYPE_REGISTER } from "src/enums";
import { Toast, Dialog } from "vant";
import patterns from "src/utils/patterns";
import { UserService } from "src/services";
import dayjs from "dayjs";
import IdentityCodeValid from "src/utils/checkIdent";

/**
 * 注册
 * @class
 * @version 1.0.0
 */
@Component
export default class Register extends Vue {
  /**
   * 是否显示密码
   * @private
   * @returns string
   */
  private showPwd: boolean = false;

  /**
   * 联系人数据模型
   * @private
   *
   */
  private modelData: any = {
    code: "",
    name: "",
    phone: "",
    smsCode: "",
    cardType: null,
    cardNo: "",
    birthDay: "",
    gender: null,
    marriageStatus: null,
    password: "",
  };

  /**
   * 性别
   * @private
   * @returns Array<any>
   */
  private sexList: Array<any> = [
    {
      id: 0,
      text: "女",
    },
    {
      id: 1,
      text: "男",
    },
  ];

  /**
   * 日期控件
   * @private
   * @returns Date
   */
  private birthDayDate: Date = dayjs().toDate();

  /**
   * 日期控件
   * @private
   * @returns Date
   */
  private minDate: Date = new Date(1900, 1, 1);

  /**
   * 最大时间
   * @private
   * @returns Date
   */
  private maxDate: Date = dayjs().toDate();

  /**
   * 出生日期弹框
   * @private
   * @returns boolean
   */
  private showDate: boolean = false;

  /**
   * 显示婚姻状态
   * @private
   * @returns boolean
   */
  private showMarriagePicker: boolean = false;

  /**
   * 性别
   * @private
   * @returns boolean
   */
  private showSexPicker: boolean = false;

  /**
   * 注册类型
   * @private
   * @returns boolean
   */
  private showMemberTypePicker: boolean = false;

  /**
   * 是否加载
   * @private
   * @returns boolean
   */
  private loading: boolean = false;

  /**
   * 显示身份证类型table
   * @private
   * @returns boolean
   */
  private showIdtypePicker: boolean = false;

  /**
   * 验证是否正确手机号
   * @private
   * @returns void
   */
  private validatorPhone(val: any): any {
    return patterns.mobile.test(val);
  }

  /**
   * 登录
   * @private
   * @returns void
   */
  private onLogin(): any {
    this.$router.push({ name: "login" });
  }

  /**
   * 与本人关系数据集合
   * @private
   * @returns Array<any>
   */
  private get relationshipList(): Array<any> {
    let relationshipList = EnumUtils.getEntries(RELATION);

    return relationshipList;
  }

  /**
   * 身份证类型数据集合
   * @private
   * @returns Array<any>
   */
  private get idTypeList(): Array<any> {
    return EnumUtils.getEntries(ID_TYPE_REGISTER);
  }

  /**
   * 结婚状态集合
   * @private
   * @returns Array<any>
   */
  private get marriageStatusList(): Array<any> {
    return EnumUtils.getEntries(MARRIAGE_STATUS_REGISTER);
  }

  /**
   * 获取当前登录机构信息
   * @private
   * @returns void
   */
  private get orgInfo(): any {
    return this.$store.getters.orgInfo || {};
  }

  /**
   * 确定日期选择
   * @private
   * @returns void
   */
  private onDateConfirm(date: any): void {
    if (date) {
      this.modelData.birthDay = dayjs(date).format("YYYY/MM/DD");
    }

    this.showDate = false;
  }

  /**
   * 取消日期选择
   * @private
   * @returns void
   */
  private onDateCancel(): void {
    this.showDate = false;
  }

  /**
   * 获取身份类型
   * @private
   * @returns string
   */
  private getIdTypeName(value: any): string {
    return EnumUtils.getEntry(value, ID_TYPE_REGISTER) && EnumUtils.getEntry(value, ID_TYPE_REGISTER).description;
  }

  /**
   * 获取关系
   * @private
   * @returns string
   */
  private getRelationName(value: any): string {
    return EnumUtils.getEntry(value, RELATION) && EnumUtils.getEntry(value, RELATION).description;
  }

  /**
   * 确定选择身份证类型
   * @private
   * @returns void
   */
  private onIdTypeConfirm(data: any): void {
    this.modelData.idTypeName = data.description;
    this.modelData.cardType = data.value;
    this.showIdtypePicker = false;
  }

  /**
   * 婚姻状态
   * @private
   * @returns void
   */
  private onMarriagePickerConfirm(data: any): void {
    this.modelData.marriageName = data.description;
    this.modelData.marriageStatus = data.value;
    this.showMarriagePicker = false;
  }

  /**
   * 确定选择性别
   * @private
   * @returns void
   */
  private onSexPickerConfirm(data: any): void {
    this.modelData.sexName = data.text;
    this.modelData.gender = data.id;
    this.showSexPicker = false;
  }

  /**
   * 确定注册类型
   * @private
   * @returns void
   */
  private onMemberTypePickerConfirm(data: any): void {
    this.modelData.memberType = data.id;
    this.modelData.memberTypeName = data.text;
    this.showMemberTypePicker = false;
  }

  /**
   * 验证身份证号
   * @private
   * @returns void
   */
  private validatorCardNo(value: any): boolean {
    if (this.modelData.cardType !== 1) {
      return true;
    }
    let data = value.toUpperCase();
    return IdentityCodeValid(data);
  }

  /**
   * 注册
   * @private
   * @returns void
   */
  private async onSubmit(): Promise<void> {
    // 验证表单数据
    if (!this.loading) {
      this.loading = true;
      try {
        let gender = this.modelData.gender;
        let birthDay = this.modelData.birthDay;
        let cardNo = this.modelData.cardNo;
        if (this.modelData.cardType === 1) {
          if (!this.validatorCardNo(cardNo)) {
            Toast("请输入正确的身份证号码！");
            return;
          }
          if (cardNo.length === 18) {
            gender = cardNo.substr(16, 1) % 2 === 0 ? 0 : 1;
            birthDay = cardNo.substr(6, 4) + "/" + cardNo.substr(10, 2) + "/" + cardNo.substr(12, 2);
          }

          if (cardNo.length === 15) {
            gender = cardNo.substr(13, 1) % 2 === 0 ? 0 : 1;
            birthDay = "19" + cardNo.substr(6, 2) + "/" + cardNo.substr(8, 2) + "/" + cardNo.substr(10, 2);
          }
        }
        this.modelData.gender = gender;
        this.modelData.birthDay = birthDay;
        this.modelData.code = localStorage.getItem("appid");
        let { content: result } = await UserService.instance.signUpClientAuth(this.modelData);

        Toast.success("注册成功!");
        this.$router.replace({ name: "login" });
      } catch (err) {
        Toast(JSON.stringify(err));
      } finally {
        this.loading = false;
      }
    }
  }

  /**
   * 获取验证码
   * @private
   * @returns boolean
   */
  private isShowGetCode: boolean = false;

  /**
   * 计时器
   * @private
   * @returns any
   */
  private timer: any = null;

  /**
   * 计时器 - 时间
   * @private
   * @returns number
   */
  private timerNum: number = 60;

  /**
   * 开始计时
   * @private
   * @returns void
   */
  private startTiming(): void {
    this.isShowGetCode = false;
    this.timer = window.setInterval(() => {
      if (this.timerNum > 1) {
        this.timerNum--;
      } else {
        window.clearInterval(this.timer);
        this.isShowGetCode = true;
        this.timerNum = 60;
      }
    }, 1000);
  }

  /**
   * 获取验证码
   * @private
   * @returns void
   */
  private async onGetCode(): Promise<void> {
    if (!this.modelData.phone) {
      Toast("请输入手机号！");
      return;
    } else if (!this.validatorPhone(this.modelData.phone)) {
      Toast("请输入正确的手机号！");
      return;
    }
    try {
      await UserService.instance.smsSend({ phone: this.modelData.phone, smsType: 4 });
      Toast("验证码发送成功");
      this.startTiming();
    } catch (err) {
      Toast(err);
    }
  }
}
