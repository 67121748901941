
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { PrivacyTermService } from "src/services";
import { Toast, Dialog } from "vant";
import pageBack from "src/components/page-back";

@Component({
  components: {
    pageBack,
  },
})
export default class Privacy extends Vue {
  /**
   * id
   * @private
   * @returns string
   */
  @Prop({ type: [String, Number], default: "" })
  private id: string;

  /**
   * 隐私条款
   * @private
   * @returns any
   */
  private privacyTermInfo: any = {
    id: null,
    title: "",
    version: "",
    abstract: "",
    mainBody: "",
    privacyTermTime: "",
  };

  /**
   * 组件创建钩子
   * @private
   * @returns void
   */
  private created() {
    this.getPrivacyTerm();
  }

  /**
   * 返回
   */
  private customFn() {
    this.$emit("close-privacy");
  }

  /**
   * 先判断是否有未同意的协议
   * @private
   * @returns void
   */
  private async getPrivacyTerm(): Promise<void> {
    try {
      let { content: result } = await PrivacyTermService.instance.getPrivacyTerm(this.id);
      if (result.data) {
        this.privacyTermInfo = Object.assign({}, result.data);
      }
    } catch (err) {
      Toast(JSON.stringify(err));
    }
  }
}
